<script setup lang="ts">
import type { AhBreadcrumb } from '~/types/models/page';

defineProps<{
    breadcrumbs: AhBreadcrumb[];
}>();

const { pathToSeoUrl } = useLanguageSelect();
</script>

<template>
    <nav aria-label="Breadcrumb">
        <ol class="flex flex-wrap items-center text-sm text-gray-600 pl-0 mb-0 list-none gap-y-1">
            <template
                v-for="(breadcrumb, index) in breadcrumbs"
                :key="index"
            >
                <li class="flex items-center">
                    <NuxtLink
                        v-if="breadcrumb.seoPath"
                        :to="pathToSeoUrl(breadcrumb.seoPath)"
                        class="mr-2"
                        :class="{
                            'font-bold': index === breadcrumbs.length - 1,
                        }"
                    >
                        {{ breadcrumb.name }}
                    </NuxtLink>
                    <span
                        v-else
                        class="mr-2"
                        :class="{
                            'font-bold': index === breadcrumbs.length - 1,
                        }"
                    >
                        {{ breadcrumb.name }}
                    </span>
                    <LazySvgoAngleRight
                        v-if="index !== breadcrumbs.length - 1"
                        class="h-4 w-auto mr-2"
                    />
                </li>
            </template>
        </ol>
    </nav>
</template>
